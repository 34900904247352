Vgr.constructors.Chart = function Chart(
	title,
	type,
	series,
	labels,
	data,
	colors,
	showLegend,
	startAtZero,
	showYAxesLegend,
	yAxesLegend,
	xAxesLegend,
	maintainAspectRatio,
	yAxisFormatFunc,
	hideXAxisLabel,
	exportButton,
	informationMessage
) {
	"use strict";

	this.title = title;
	this.series = series;
	this.labels = labels;
	this.data = data;
	this.type = type;
	this.exportButton = exportButton;
	this.informationMessage = informationMessage;
	this.loading = false;
	showLegend = showLegend == null ? true : showLegend;
	startAtZero = startAtZero == null ? true : startAtZero;
	showYAxesLegend = showYAxesLegend == null ? false : showYAxesLegend;
	maintainAspectRatio = maintainAspectRatio == null ? false : maintainAspectRatio;
	if (type == "line") {
		this.options = {
			responsive: true,
			maintainAspectRatio: maintainAspectRatio,
			legend: {
				display: showLegend,
				position: "top",
				fullWidth: false
			},
			scales: {
				yAxes: [
					{
						id: "y-axis-1",
						type: "linear",
						display: true,
						position: "left",
						ticks: {
							beginAtZero: startAtZero
						},
						scaleLabel: {
							display: showYAxesLegend,
							labelString: yAxesLegend
						}
					}
				],
				xAxes: [
					{
						ticks: {
							display: hideXAxisLabel ? false : true //this will remove only the label
						},
						gridLines: {
							display: false
						}
					}
				]
			}
		};
	} else if (type == "bar") {
		this.options = {
			responsive: true,
			maintainAspectRatio: maintainAspectRatio,
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: startAtZero
						}
					}
				],
				xAxes: [
					{
						ticks: {
							display: hideXAxisLabel ? false : true //this will remove only the label
						},
						gridLines: {
							display: false
						}
					}
				]
			}
		};
	} else if (type == "pie") {
		this.options = {
			responsive: true,
			maintainAspectRatio: maintainAspectRatio,
			legend: {
				display: showLegend,
				position: "right",
				fullWidth: false
			}
		};
	}

	this.datasetOverride = [
		{
			yAxisID: "y-axis-1"
		}
	];
	this.colors = colors || Vgr.constants.chartColors;

	if (
		yAxisFormatFunc &&
		this.options &&
		this.options.scales &&
		this.options.scales.yAxes &&
		this.options.scales.yAxes[0].ticks
	) {
		this.options.scales.yAxes[0].ticks.callback = yAxisFormatFunc;
	}
};

angular.module("vgresiduos").controller("DashboardCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"$sce",
	"dashboardService",
	"accountService",
	"httpService",
	"engagementScoreService",
	"pendencyService",
	"$q",
	"supplierDocumentService",
	"userAccessService",
	"clientStoragePreferenceService",
	function (
		$rootScope,
		$scope,
		$state,
		$sce,
		dashboardService,
		accountService,
		httpService,
		engagementScoreService,
		pendencyService,
		$q,
		supplierDocumentService,
		userAccessService,
		clientStoragePreferenceService
	) {
		"use strict";

		//generator pendencies - suppliers
		const organizationUnitSupplierPendencies = [
			Vgr.enumerations.pendency.CadrisToExpire,
			Vgr.enumerations.pendency.CadrisExpired,
			Vgr.enumerations.pendency.CadrisWithProtocol
		];
		const disposalFollowupPendencies = [
			Vgr.enumerations.pendency.RequestedDisposals,
			Vgr.enumerations.pendency.PendingDisposals
		];

		const viewStorageViewGenerated =
			userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesGeneratedFromAllAreas) ||
			userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesGeneratedFromUsersAreas);
		const viewStorageViewStored =
			userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesStoredFromAllAreas) ||
			userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesStoredFromUsersAreas);

		const seriesColors = {};
		const availableColors = angular.copy(Vgr.constants.chartColors);

		availableColors.reverse();

		// Scope variables
		$scope.currentClient = accountService.getCurrentClient();
		$scope.pendencies = [];
		$scope.loading = true;
		$scope.options = {
			loading: $scope.loading
		};
		$scope.charts = $scope.isSupplier ? getSupplierChart() : getGeneratorChart();
		$scope.generalStatus = Vgr.constants.generalStatus;

		// Scope functions
		$scope.loadCharts = loadCharts;

		$scope.onPendencyClick = function (state, pendencyType, params) {
			const pendencyKey = Object.keys(Vgr.enumerations.pendency).find(
				(key) => Vgr.enumerations.pendency[key] === pendencyType
			);

			engagementScoreService.track(Vgr.trackings.dashboard.pendencies.clickedOnAPendency, null, {
				Pendency: pendencyKey
			});

			$state.go(state, params);
		};

		$scope.goToHome = function () {
			$state.go("home");
		};

		// RootScope variables
		$rootScope.contentNavBar = {};
		$rootScope.contentNavBar.title = $scope.labels.DASHBOARD;
		$rootScope.contentNavBar.removeButtonBar = true;

		this.$onInit = function () {
			$rootScope.$broadcast(Vgr.constants.evtShowMenu);

			$scope.loadCharts();
			$scope.loading = false;
			listPendencies();
		};

		function listPendencies() {
			const dto = {
				isToGroup: true
			};
			$scope.loadingDashboardPendencies = true;
			httpService.getListFromServiceV2(pendencyService.listPendencies, dto, "Pendencies").then(
				function (list) {
					formatPendencies(list);
					$scope.loadingDashboardPendencies = false;
				},
				function () {
					$scope.loadingDashboardPendencies = false;
				}
			);
		}

		function listResidueStoragePendencies() {
			const dto = {
				isToGroup: false,
				type: Vgr.enumerations.pendency.ResidueStorageAlert
			};
			httpService.getListFromServiceV2(pendencyService.listPendencies, dto, "Pendencies").then(
				function (list) {
					for (const pendency of list) {
						pendency.messageHtml = getFormattedMessage(pendency);
						pendency.Click = getFunctionForPendency(pendency.Type, pendency.ReferenceValue, pendency.Value);
						$scope.pendencies.push(pendency);
					}

					$scope.loading = false;
				},
				function () {}
			);
		}

		function loadCharts() {
			let indexDestinated = 1;
			let indexUsers = 3;
			if ($scope.isSupplier) {
				indexDestinated = 0;
				indexUsers = 1;
			}

			dashboardService.getResiduesDestinated().then(
				function (response) {
					if (response.data.success) {
						const list = Vgr.util.extractList(response.data.content.list);
						if (list.length === 0) {
							$scope.charts[indexDestinated].noData = true;
							$scope.charts[indexDestinated].loading = false;
						} else {
							const series = getSeries(list);
							const labels = getLabels(list);
							const data = [];
							const colors = [];
							series.forEach(function (serie) {
								data.push(getSeriesValue(list, serie));
								colors.push(getColor(serie));
							});
							$scope.charts[indexDestinated] = new Vgr.constructors.Chart(
								$scope.charts[indexDestinated].title,
								"line",
								series,
								labels,
								data,
								colors
							);
						}
					}
				},
				function () {
					$scope.charts[indexDestinated].errorLoading = true;
					$scope.charts[indexDestinated].loading = false;
				}
			);

			if (!$scope.isSupplier) {
				dashboardService.getResiduesGenerated().then(
					function (response) {
						if (response.data.success) {
							const list = Vgr.util.extractList(response.data.content.list);
							if (list.length === 0) {
								$scope.charts[0].noData = true;
								$scope.charts[0].loading = false;
							} else {
								const series = getSeries(list);
								const labels = getLabels(list);
								const data = [];
								const colors = [];
								series.forEach(function (serie) {
									data.push(getSeriesValue(list, serie));
									colors.push(getColor(serie));
								});
								$scope.charts[0] = new Vgr.constructors.Chart(
									$scope.charts[0].title,
									"line",
									series,
									labels,
									data,
									colors
								);
							}
						}
					},
					function () {
						$scope.charts[0].errorLoading = true;
						$scope.charts[0].loading = false;
					}
				);
			}

			dashboardService.getUsersMoreAccesses().then(
				function (response) {
					if (response.data.success) {
						const list = Vgr.util.extractList(response.data.content.list);
						if (list.length === 0) {
							$scope.charts[indexUsers].noData = true;
							$scope.charts[indexUsers].loading = false;
						} else {
							const labels = getLabels(list);
							const data = [];
							list.forEach(function (reportColumn) {
								data.push(reportColumn.Valor);
							});
							$scope.charts[indexUsers] = new Vgr.constructors.Chart(
								$scope.charts[indexUsers].title,
								"bar",
								null,
								labels,
								data
							);
						}
					}
				},
				function () {
					$scope.charts[indexUsers].errorLoading = true;
					$scope.charts[indexUsers].loading = false;
				}
			);

			if (!$scope.isSupplier) {
				dashboardService.getLicensesToExpire().then(
					function (response) {
						if (response.data.success) {
							const list = Vgr.util.extractList(response.data.content.list);
							if (list.length === 0) {
								$scope.charts[2].noData = true;
								$scope.charts[2].loading = false;
							} else {
								const labels = getSeries(list);
								const data = [];
								list.forEach(function (reportColumn) {
									data.push(reportColumn.Valor);
								});
								$scope.charts[2] = new Vgr.constructors.Chart(
									$scope.charts[2].title,
									"pie",
									null,
									labels,
									data,
									undefined,
									undefined,
									undefined,
									undefined,
									undefined,
									undefined,
									undefined,
									undefined,
									undefined,
									$scope.charts[2].exportButton,
									$scope.charts[2].informationMessage
								);
							}
						}
					},
					function () {
						$scope.charts[2].errorLoading = true;
						$scope.charts[2].loading = false;
					}
				);
			}
		}

		function getColor(serie) {
			if (seriesColors[serie]) {
				return seriesColors[serie];
			}
			seriesColors[serie] = availableColors.pop();
			return seriesColors[serie];
		}

		function getSeries(list) {
			const series = [];
			list.forEach(function (reportColumn) {
				if (series.indexOf(reportColumn.Serie) === -1) {
					series.push(reportColumn.Serie);
				}
			});
			return series;
		}

		function getSeriesValue(list, serie) {
			const data = [];
			list.forEach(function (reportColumn) {
				if (reportColumn.Serie === serie) {
					data.push(reportColumn.Valor);
				}
			});
			return data;
		}

		function getLabels(list) {
			const labels = [];
			list.forEach(function (reportColumn) {
				if (labels.indexOf(reportColumn.Categoria) === -1) {
					labels.push(reportColumn.Categoria);
				}
			});
			return labels;
		}

		function formatPendencies(list) {
			$scope.pendencies = [];

			for (const pendency of list) {
				if (pendency.Type == Vgr.enumerations.pendency.ResidueStorageAlert) {
					listResidueStoragePendencies();
				} else {
					pendency.messageHtml = getFormattedMessage(pendency);
					pendency.Click = getFunctionForPendency(pendency.Type, pendency.ReferenceValue, pendency.Value);
					$scope.pendencies.push(pendency);
				}
			}
		}

		function getFormattedMessage(pendency) {
			let message = $rootScope.labels.PENDENCIES_MESSAGES[pendency.Type];
			if (message) {
				message = message.replace("[NUMERO]", '<b class="f-s-15">' + pendency.Value + "</b>");
				message = message.replace("[DIRETIVA]", '<b class="f-s-15">' + pendency.ReferenceValue + "</b>");
				return $sce.trustAsHtml(message);
			}
			return "";
		}

		function getFunctionForPendency(pendencyType, pendencyReferenceValue, pendencyValue) {
			//generator pendencies - suppliers
			if (organizationUnitSupplierPendencies.indexOf(pendencyType) !== -1) {
				return function () {
					$scope.onPendencyClick("transporterList", pendencyType, { pendencia: pendencyType });
				};
			}

			//generator pendencies - suppliers
			if (disposalFollowupPendencies.indexOf(pendencyType) !== -1) {
				return function () {
					$scope.onPendencyClick("disposalFollowup", pendencyType);
				};
			}

			switch (pendencyType) {
				case Vgr.enumerations.pendency.SupplierDocumentsPendingApproval:
				case Vgr.enumerations.pendency.SuppliersWithExpiredDriversLicense:
				case Vgr.enumerations.pendency.SuppliersWithDriversLicenseToExpire:
				case Vgr.enumerations.pendency.SuppliersWithExpiredDriversMOPP:
				case Vgr.enumerations.pendency.SuppliersWithDriversMOPPToExpire:
				case Vgr.enumerations.pendency.SuppliersWithExpiredVehicleInmetroCertificate:
				case Vgr.enumerations.pendency.SuppliersWithVehicleInmetroCertificateToExpire:
				case Vgr.enumerations.pendency.ExpiredAudit:
				case Vgr.enumerations.pendency.CloseExpirationAudit:
				case Vgr.enumerations.pendency.ExpiredActionPlanItem:
				case Vgr.enumerations.pendency.CloseExpirationActionPlanItem:
				case Vgr.enumerations.pendency.DocumentToExpire:
				case Vgr.enumerations.pendency.DocumentExpired:
				case Vgr.enumerations.pendency.DocumentWithProtocol:
				case Vgr.enumerations.pendency.RequiredDocumentMissing:
				case Vgr.enumerations.pendency.WaitingForSupplierAudit:
				case Vgr.enumerations.pendency.WithoutCtfDocument:
				case Vgr.enumerations.pendency.ActionPlanMandatory:
				case Vgr.enumerations.pendency.WithoutAudits:
				case Vgr.enumerations.pendency.OptionalDocumentMissing:
				case Vgr.enumerations.pendency.GeneralAuditPendency:
					return function () {
						$scope.onPendencyClick("supplierList", pendencyType, {
							pendencia: pendencyType,
							unidade: accountService.getCurrentClient().id
						});
					};
				case Vgr.enumerations.pendency.PendingInternalGatherings:
					if (!viewStorageViewGenerated) {
						return function () {
							$rootScope.$broadcast(
								Vgr.constants.evtShowErrorMessage,
								$rootScope.labels["FORBIDDEN_VIEW_RESIDUES_GENERATED"]
							);
							$scope.onPendencyClick("storageList", pendencyType);
						};
					}

					return function () {
						$scope.onPendencyClick("storageList", pendencyType, { tab: 0 });
					};
				case Vgr.enumerations.pendency.ResidueStorageAlert:
					if (!viewStorageViewStored) {
						return function () {
							$rootScope.$broadcast(
								Vgr.constants.evtShowErrorMessage,
								$rootScope.labels["FORBIDDEN_VIEW_RESIDUES_STORED"]
							);
							$state.go("home");
						};
					}

					return function () {
						clientStoragePreferenceService.getStoragePreferences().then(function (storagePreference) {
							let tab = 2;

							if (
								storagePreference.internalTransferType ==
								Vgr.enumerations.internalGathering.internalTransferType.TwoStepTransfer
							) {
								tab = 1;
							} else if (
								storagePreference.internalTransferType ==
								Vgr.enumerations.internalGathering.internalTransferType.NoTransfer
							) {
								tab = 0;
							}

							$scope.onPendencyClick("storageList", pendencyType, {
								residuo: pendencyReferenceValue,
								nomeResiduo: pendencyValue,
								tab: tab
							});
						});
					};
			}

			throw new Error("pendency " + pendencyType + " invalid");
		}

		function getGeneratorChart() {
			return [
				{
					title: $rootScope.labels.RESIDUES_GENERATED,
					loading: true
				},
				{
					title: $rootScope.labels.RESIDUES_DESTINATED,
					loading: true
				},
				{
					title: $rootScope.labels.ORGANIZATION_DOCUMENTS,
					loading: true,
					exportButton: {
						label: "",
						buttonClasses: "md-primary",
						icon: "ios_share",
						tooltip: $scope.labels.EXPORT,
						onClick: function () {
							const deferred = $q.defer();

							httpService.postDTOToServiceV2(supplierDocumentService.generateDocumentsReport, {}).then(function (data) {
								if (data != null) {
									const fileDTO = Vgr.util.fileDtoToFile({
										Extension: data.extension,
										Name: data.name,
										FileBytes: data.fileBytes
									});
									saveAs(fileDTO, fileDTO.FileName);
								}
								deferred.resolve();
							});

							return deferred.promise;
						}
					},
					informationMessage: {
						title: $rootScope.labels.ORGANIZATION_DOCUMENTS_NEW_ACCESS,
						buttonText: $rootScope.labels.CLICK_AND_KNOW_MORE,
						onClick: function () {
							$state.go("supplierList");
						}
					}
				},
				{
					title: $rootScope.labels.USERS_MORE_ACCESSES,
					loading: true
				}
			];
		}

		function getSupplierChart() {
			return [
				{
					title: $rootScope.labels.RESIDUES_DESTINATED,
					loading: true
				},
				{
					title: $rootScope.labels.USERS_MORE_ACCESSES,
					loading: true
				}
			];
		}
	}
]);
