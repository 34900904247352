/* eslint-disable no-undef */
angular.module("vgresiduos").controller("notificationPopupsCtrl", [
	"$scope",
	"$rootScope",
	"$location",
	"accountService",
	"clientService",
	"httpService",
	"notificationsService",
	"localStorageService",
	"userAccessService",
	"dialogService",
	function (
		$scope,
		$rootScope,
		$location,
		accountService,
		clientService,
		httpService,
		notificationsService,
		localStorageService,
		userAccessService,
		dialogService
	) {
		"use strict";

		let isAlertOpened = false;
		const onChangeLoggedClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
			buildDmrPopup();
			buildAlertPopups();
			buildNewFeaturesPopup();
		});

		this.$onInit = function () {
			buildDmrPopup();
			buildAlertPopups();
			buildNewFeaturesPopup();
		};

		function isCurrentClientPayer() {
			if (!accountService.getCurrentClient()) {
				return false;
			}

			const contractTypePayerStatus = [
				Vgr.enumerations.organization.contractType.Payer,
				Vgr.enumerations.organization.contractType.Trial
			];

			return contractTypePayerStatus.includes(accountService.getCurrentClient().organization.contractType);
		}

		function buildNewFeaturesPopup() {
			if (!isCurrentClientPayer() || clientService.isSupplier() || isAlertOpened) {
				return;
			}

			httpService
				.getListFromServiceCore(notificationsService.listNotifications, { onlyActives: true })
				.then(function (response) {
					const foundPopup = response.list.find((n) => shouldShowNewFeaturePopup(n));
					if (foundPopup) {
						const popupData = getNewFeaturePopupData(foundPopup.popup);
						openNewFeaturesPopup(popupData);
					}
				});
		}

		function shouldShowNewFeaturePopup(notification) {
			return (
				notification.popup &&
				!localStorageService.get(notification.popup.localStorageKey) &&
				(!notification.featureToggleId || userAccessService.hasAccess(null, notification.featureToggleId))
			);
		}

		function getNewFeaturePopupData(popupData) {
			const buttons = [
				{
					label: $scope.labels.CLOSE,
					class: "md-primary",
					click: function () {
						dialogService.confirm();
					}
				}
			];
			if (popupData.pageUrl) {
				buttons.push({
					label: $scope.labels.ACCESS,
					class: "md-primary md-raised",
					click: function () {
						dialogService.confirm();

						$location.path(`/${popupData.pageUrl}`);
					}
				});
			}

			return {
				key: popupData.localStorageKey,
				imageLink: Vgr.constants.s3BaseUrl + popupData.imagePath,
				isManualAccess: !!popupData.manualUrl,
				knowMore: function () {
					const url = Vgr.constants.zendesk.baseUrl + popupData.manualUrl;
					window.open(url, "_blank");
				},
				buttons: buttons
			};
		}

		function openNewFeaturesPopup(popupModel) {
			isAlertOpened = true;
			const disablePopup = localStorageService.get(popupModel.key);
			if (disablePopup || popupModel == null) {
				return;
			}
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/newFeaturesPopup/newFeaturesPopup_template.html",
					"NewFeaturesPopupCtrl",
					".vgr-page",
					{ popupModel: popupModel },
					true
				)
				.then(
					function () {
						isAlertOpened = false;
						localStorageService.set(popupModel.key, true);
					},
					function () {
						isAlertOpened = false;
						localStorageService.set(popupModel.key, true);
					}
				);
		}

		function buildAlertPopups() {
			if (!isCurrentClientPayer() || clientService.isSupplier() || isAlertOpened) {
				return;
			}

			const popupModel = getAlertPopupData();
			if (popupModel && !localStorageService.get(popupModel.key)) {
				openAlertPopup(popupModel);
			}
		}

		function getAlertPopupData() {
			return {
				key: Vgr.enumerations.newFeaturesKey.imaAlertPopup,
				imageLink: "/img/generalTopAlerts/imaAlert.svg",
				hasGenericMessage: true,
				knowMore: function () {
					const url = Vgr.constants.imaAlertUrl.baseUrl;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.I_KNOW,
						class: "md-primary md-raised",
						click: function () {
							dialogService.confirm();
						}
					}
				]
			};
		}

		function openAlertPopup(popupModel) {
			isAlertOpened = true;
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/alertPopup/alertPopup_template.html",
					"AlertPopupCtrl",
					".vgr-page",
					{ popupModel: popupModel },
					true
				)
				.then(
					function () {
						isAlertOpened = false;
						localStorageService.set(popupModel.key, true);
					},
					function () {
						isAlertOpened = false;
						localStorageService.set(popupModel.key, true);
					}
				);
		}

		function buildDmrPopup() {
			const dateMoment = moment();
			const hasDmrAccess = userAccessService.hasAccess(Vgr.systemPermissions.GenerateDmr);
			const dmrKeyPrefix = "dmrAlertPopup_";
			const dmrAlertPopupKey = `${dmrKeyPrefix}${dateMoment.quarter()}_${dateMoment.year()}`;
			const disableDmrAlertPopup = localStorageService.get(dmrAlertPopupKey);
			if (
				!disableDmrAlertPopup &&
				isPeriodToShowDmrAlert(dateMoment) &&
				hasDmrAccess &&
				!accountService.isMarketProfile() &&
				isAlertOpened
			) {
				openDmrPopup();
			}
		}

		function openDmrPopup() {
			isAlertOpened = true;
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/dmrAlertPopup/dmrAlertPopup_template.html",
					"dmrAlertPopupCtrl",
					".vgr-page",
					{ alertPopupModel: null },
					true
				)
				.then(
					function () {
						isAlertOpened = false;
					},
					function () {
						isAlertOpened = false;
					}
				);
		}

		function isPeriodToShowDmrAlert(dateMoment) {
			const dayOfMonth = dateMoment.date();
			const currentMonth = dateMoment.month() + 1;
			const monthsAlert = [1, 4, 7, 10]; // meses p/ alerta
			return monthsAlert.includes(currentMonth) && dayOfMonth >= 5;
		}

		this.$onDestroy = function () {
			// disable the listener
			onChangeLoggedClient();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};
	}
]);
