angular.module("componentes").factory("notificationsService", [
	"$http",
	function ($http) {
		"use strict";

		const _listNotifications = function (params) {
			const onlyActives = params.onlyActives ?? false;

			const req = {
				method: "get",
				url: `${Vgr.constants.sharedServicesHostUrl}${Vgr.resources.sharedServices.notifications}?onlyActives=${onlyActives}`
			};
			return $http(req);
		};

		return {
			listNotifications: _listNotifications
		};
	}
]);
