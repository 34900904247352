angular.module("vgresiduos").controller("iframeAngularController", [
	"$scope",
	"$sce",
	"accountService",
	"localStorageWithTimeoutService",
	"translationService",
	"$interval",
	"$state",
	"$q",
	"$window",
	"$rootScope",
	function (
		$scope,
		$sce,
		accountService,
		localStorageWithTimeoutService,
		translationService,
		$interval,
		$state,
		$q,
		$window,
		$rootScope
	) {
		const eventHandler = {
			REDIRECT: redirectTo,
			READY: syncLocalStorage,
			REDIRECT_BLANK: redirectBlank,
			REDIRECT_PAGE_URL: redirectToPageUrl,
			ON_SUCCESS: executeOnSuccessCall,
			ON_CANCEL: executeOnCancelCall
		};

		$scope.labels = $rootScope.labels;

		const iframeId = Vgr.constants.IframeId;
		let refreshTokenTimer;

		this.$onInit = function () {
			$scope.loading = true;

			createUrl();
			window.addEventListener("message", iframeEventHandler);
			refreshTokenTimer = $interval(refreshFrameToken, 1000 * 5 * 60); //Refresh Angular token every 5 minutes
		};

		this.$onDestroy = function () {
			window.removeEventListener("message", iframeEventHandler);
			$interval.cancel(refreshTokenTimer);
			disableLoading();
		};

		function iframeEventHandler(event) {
			if (Vgr.constants.newAngularUrl.includes(event.origin) && event.data.type) {
				eventHandler[event.data.type](event);
			}
		}

		function executeOnSuccessCall(event) {
			if (event && event.data && event.data.payload && event.data.payload.createVehicle) {
				$scope.$emit(Vgr.constants.evtIframeOnSuccessCreateVehicle, event.data.payload);
			} else if (event && event.data && event.data.payload && event.data.payload.createDriver) {
				$scope.$emit(Vgr.constants.evtIframeOnSuccessCreateDriver, event.data.payload);
			} else {
				$scope.$emit(Vgr.constants.evtIframeOnSuccessCallBack, event);
			}
		}

		function executeOnCancelCall(event) {
			if (event && event.data && event.data.payload && event.data.payload.createVehicle) {
				$scope.$emit(Vgr.constants.evtIframeOnCancelCreateVehicle, event);
			} else if (event && event.data && event.data.payload && event.data.payload.createDriver) {
				$scope.$emit(Vgr.constants.evtIframeOnCancelCreateDriver, event);
			}
		}

		function redirectTo(event) {
			const url = $state.href(event.data.payload.state, event.data.payload.routeData);
			$window.open(url, "_self");
		}

		function redirectBlank(event) {
			window.open(event.data.payload.blankUrl, "_blank");
		}

		function redirectToPageUrl(event) {
			window.open(event.data.payload.pageUrl, "_self");
		}

		function createUrl() {
			const baseUrl = Vgr.constants.newAngularUrl;
			const path = $scope.ctrl.path;

			createQueryParams().then((queryParams) => {
				const finalUrl = `${baseUrl}${path}${queryParams}`;
				$scope.src = $sce.trustAsResourceUrl(finalUrl);
			});
		}

		function getPermissions() {
			return localStorageWithTimeoutService.getFromCacheWithExpiry(
				Vgr.constants.authentication.permissions,
				Vgr.constants.events.cache.userPermission
			);
		}
		function getFeatureToggle() {
			return localStorageWithTimeoutService.getFromCacheWithExpiry(
				Vgr.constants.authentication.featureToggle,
				Vgr.constants.events.cache.featureToggle
			);
		}

		function refreshFrameToken() {
			getBearerToken().then((token) => {
				if (token) {
					sendMessageToFrame(Vgr.enumerations.messageTypes.SET_BEARER_TOKEN, { token: token });
				}
			});
		}

		function syncLocalStorage() {
			getBearerToken().then((token) => {
				const currentLanguage = translationService.getLanguage();
				const userPermissions = getPermissions();
				const organization = accountService.getCurrentClient();
				const featureToggles = getFeatureToggle();
				const loggedUser = accountService.getLoggedAccount();
				sendMessageToFrame(Vgr.enumerations.messageTypes.SYNC_LOCAL_STORAGE, {
					userPermissions,
					organization,
					featureToggles,
					currentLanguage,
					loggedUser,
					token
				});
			});
		}

		function createQueryParams() {
			const deferred = $q.defer();
			const queryParams = [];
			const withHeader = $scope.ctrl.withHeader ? $scope.ctrl.withHeader : false;
			queryParams.push(`?withHeader=${withHeader}`);
			if ($scope.ctrl.queries) {
				const pathQueries = JSON.parse($scope.ctrl.queries);
				pathQueries.forEach((query) => {
					if (query.label && query.value) {
						queryParams.push(`${query.label}=${query.value}`);
					}
				});
			}

			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitId = client.id;
			const organizationUnitCode = client.code;
			queryParams.push(`organizationId=${organizationId}`);
			queryParams.push(`organizationUnitId=${organizationUnitId}`);
			queryParams.push(`organizationUnitCode=${organizationUnitCode}`);

			deferred.resolve(queryParams.reduce((prev, current) => `${prev}&${current}`));
			return deferred.promise;
		}

		function getBearerToken() {
			if (!document.getElementById(iframeId)) {
				return;
			}

			const deferred = $q.defer();
			accountService.getTokenRefreshingIfNecessary().then((tokens) => {
				if (tokens) {
					deferred.resolve(tokens.accessToken);
				} else {
					deferred.resolve();
				}
			});
			return deferred.promise;
		}

		function sendMessageToFrame(type, payload) {
			document.getElementById(iframeId).contentWindow.postMessage(
				{
					type: type,
					payload: payload
				},
				Vgr.constants.newAngularUrl
			);
		}

		$(`#${iframeId}`).ready(function () {
			$(`#${iframeId}`).on("load", function () {
				disableLoading();
			});

			setTimeout(function () {
				if ($scope.loading) {
					showLoadingWaitMessage();
				}
			}, 5000);
		});

		function disableLoading() {
			$scope.$applyAsync(function () {
				$scope.loading = false;
			});
		}

		function showLoadingWaitMessage() {
			$scope.$applyAsync(function () {
				$scope.showLoadingWaitMessage = true;
			});
		}
	}
]);
