/* eslint-disable no-undef */
angular.module("vgresiduos").controller("generalTopAlertsCtrl", [
	"$scope",
	"$rootScope",
	"accountService",
	"localStorageService",
	"$state",
	"dialogService",
	"userAccessService",
	"$sce",
	function ($scope, $rootScope, accountService, localStorageService, $state, dialogService, userAccessService, $sce) {
		"use strict";

		$scope.currentAlerts = [];

		let onChangeLoggedClient;
		const dateDiffParam = "[DATE_DIFF]";
		let currentClient = accountService.getCurrentClient();

		this.$onInit = function () {
			refreshAlerts();
			onChangeLoggedClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
				currentClient = accountService.getCurrentClient();
				$scope.currentAlerts = [];
				refreshAlerts();
			});
		};

		function refreshAlerts() {
			if (!currentClient) {
				return;
			}

			const contractEndAlert = getContractEndAlert();
			if (contractEndAlert != null) {
				$scope.currentAlerts.push(contractEndAlert);
			}
			const contractBlockAlert = getContractBlockAlert();
			if (contractBlockAlert != null) {
				$scope.currentAlerts.push(contractBlockAlert);
			}
			const completeRegisterAlert = getCompleteRegisterAlert();
			if (completeRegisterAlert != null) {
				$scope.currentAlerts.push(completeRegisterAlert);
			}
			const bannerInfoAlert = getBannerInfoAlert();
			if (bannerInfoAlert != null) {
				$scope.currentAlerts.push(bannerInfoAlert);
			}
		}

		function isCurrentClientPayer() {
			const contractTypePayerStatus = [
				Vgr.enumerations.organization.contractType.Payer,
				Vgr.enumerations.organization.contractType.Trial
			];

			return contractTypePayerStatus.includes(currentClient.organization.contractType);
		}

		this.$onDestroy = function () {
			// disable the listener
			onChangeLoggedClient();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		function getContractEndAlert() {
			const endContractDate = Vgr.util.getDateFromString(currentClient.organization.endDate);
			const contractType = currentClient.organization.contractType;

			const contractEndAlert = {
				key: "contractEndAlertKey",
				showEasyAlert: false,
				showHardAlert: false,
				alertMessage: null
			};
			localStorageService.set(contractEndAlert.key, false);
			if (!endContractDate || !isCurrentClientPayer()) {
				return contractEndAlert;
			}

			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			const dateDiff = Vgr.util.compareDays(endContractDate, today);

			if (dateDiff < 0) {
				contractEndAlert.showHardAlert = true;
				if (contractType == Vgr.enumerations.organization.contractType.Payer) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_END_DATE_ALERT_PAYER;
				} else if (contractType == Vgr.enumerations.organization.contractType.Trial) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_END_DATE_ALERT_TRIAL;
				}
			} else if (dateDiff < 30 && !localStorageService.get(contractEndAlert.key)) {
				contractEndAlert.showEasyAlert = true;
				if (contractType == Vgr.enumerations.organization.contractType.Payer) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_END_ALERT_PAYER.replace(
						dateDiffParam,
						dateDiff
					);
				} else if (contractType == Vgr.enumerations.organization.contractType.Trial) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_END_ALERT_TRIAL.replace(
						dateDiffParam,
						dateDiff
					);
				}
			}
			return contractEndAlert;
		}

		function getContractBlockAlert() {
			const blockDate = Vgr.util.getDateFromString(currentClient.organization.blockDate);

			const contractBlockAlert = {
				key: "contractBlockAlertKey",
				showEasyAlert: false,
				showHardAlert: false,
				alertMessage: null
			};
			localStorageService.set(contractBlockAlert.key, false);
			if (!blockDate || !isCurrentClientPayer()) {
				return contractBlockAlert;
			}

			const oneDay = 24 * 60 * 60 * 1000;
			const today = new Date();
			const dateDiff = Math.ceil((blockDate - today) / oneDay);

			if (dateDiff < 0) {
				contractBlockAlert.alertMessage = $rootScope.labels.CONTRACT_BLOCK_DATE_ALERT;
				contractBlockAlert.showHardAlert = true;
			} else if (dateDiff < 30 && !localStorageService.get(contractBlockAlert.key)) {
				const day = addZero(blockDate.getDate());
				const month = addZero(blockDate.getMonth() + 1);
				const year = blockDate.getFullYear();
				const block_day = dateStructure(day, month, year);
				contractBlockAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_BLOCK_ALERT.replace(
					"[BLOCK_DAY]",
					block_day
				);
				contractBlockAlert.showEasyAlert = true;
			}
			return contractBlockAlert;
		}

		function getCompleteRegisterAlert() {
			let completeRegisterAlert = null;
			if (currentClient.registerCompleted == undefined || !currentClient.registerCompleted) {
				completeRegisterAlert = {
					key: "completeRegisterAlertKey",
					showEasyAlert: true,
					alertMessage: $rootScope.labels.COMPLETE_REGISTER_MESSAGE,
					clickEvent: function () {
						$state.go("clientEdit", { id: currentClient.id });
					}
				};
			}
			return completeRegisterAlert;
		}

		const bannerInfoAlertKey = "disableAlertBanner_";
		function getBannerInfoAlert() {
			if ($rootScope.labels.BANNER_ALERT_MESSAGE) {
				const disableAlertBanner = localStorageService.get(bannerInfoAlertKey);
				if (!disableAlertBanner) {
					return {
						key: bannerInfoAlertKey,
						showEasyAlert: true,
						alertMessage: $rootScope.labels.BANNER_ALERT_MESSAGE,
						isHtml: false,
						clickEvent: function () {
							dialogService.confirm();
							const url = "";
							window.open(url, "_blank");
						}
					};
				}
			} else {
				localStorageService.set(bannerInfoAlertKey, false);
				return null;
			}
		}

		function addZero(variable) {
			if (variable < 10) {
				return "0" + variable;
			} else {
				return variable;
			}
		}

		function dateStructure(day, month, year) {
			return day + "/" + month + "/" + year;
		}

		$scope.closeAlert = function (storageKey) {
			const alert = $scope.currentAlerts.find((a) => a.key == storageKey);
			if (!alert.showHardAlert) {
				localStorageService.set(storageKey, true);
			}
			alert.showEasyAlert = false;
			alert.showInfoAlert = false;
			alert.showHardAlert = false;
		};

		$scope.executeAlertFunction = function (storageKey) {
			$scope.currentAlerts.forEach((a) => {
				if (a.key == storageKey && a.clickEvent) {
					a.clickEvent();
				}
			});
		};

		$scope.trustedHtml = function (plainText) {
			return $sce.trustAsHtml(plainText);
		};
	}
]);
